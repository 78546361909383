import { CloseOutlined } from '@ant-design/icons'
import { Spin, Button as CloseButton } from 'antd'
import { observer } from 'mobx-react-lite'
import { useEffect, useState } from 'react'

import PlusIcon from 'assets/icons/templates-plus.svg'
import { Heading } from 'components/common'
import EditTemplate from 'components/EmailTemplates/components/EditTemplate'
import PresentationView from 'components/EmailTemplates/components/PresentationView'
import TemplateCard from 'components/EmailTemplates/components/TemplateCard'
import ArchiveTemplateModal from 'components/EmailTemplates/modals/ArchiveTemplateModal'
import EditWarningModal from 'components/EmailTemplates/modals/EditWarningModal'
import useStore from 'store/useStore'

import type { Dayjs } from 'dayjs'

import type { EmailTemplate } from 'models/email-templates.model'

/**
 * This component is going to be rendered:
 *   - With the route /email-templates not inside a modal
 *   - From the action config panel where it will be displayed inside a modal
 */

interface TemplateListProps {
  closeTemplateListModal?: () => void
  changeActionConfigPanelFieldValue?: (
    value: boolean | number | string | string[] | Dayjs | Dayjs[] | null,
    fieldKey: string | undefined,
    index?: number,
  ) => void
  isInActionFlow?: boolean
  initializeEdit?: { isEdit: boolean; templateId: string | null; templateVersion: number | null }
}

const TemplateList = observer(
  ({
    closeTemplateListModal,
    changeActionConfigPanelFieldValue,
    isInActionFlow = false,
    initializeEdit = { isEdit: false, templateId: null, templateVersion: null },
  }: TemplateListProps) => {
    const { emailTemplatesStore } = useStore()
    const {
      data: { templateList },
      loading: { isTemplateListLoading },
      getTemplateList,
      setTemplate,
      cloneTemplate,
      archiveTemplate,
      resetSelectedTemplate,
    } = emailTemplatesStore

    const [isEditOpen, setIsEditOpen] = useState(initializeEdit.isEdit)
    const [isPresentationViewOpen, setIsPresentationViewOpen] = useState(false)
    const [isNewTemplate, setIsNewTemplate] = useState(false)
    const [isConfirmDeleteModalOpen, setIsConfirmDeleteModalOpen] = useState(false)
    const [isEditEmailModalOpen, setIsEditEmailModalOpen] = useState(false)
    // This piece of state is required for managing which template in question for the template grid dropdown
    const [currentlySelectedTemplate, setCurrentlySelectedTemplate] = useState<EmailTemplate | null>(null)

    useEffect(() => {
      // Only fetch the templates on the initial render, no templates being returned from the API is valid
      getTemplateList().catch(console.error)
    }, [])

    const handleNewTemplateClick = () => {
      resetSelectedTemplate()
      setIsEditOpen(true)
      setIsNewTemplate(true)
    }

    const handleEditTemplateClick = (template: EmailTemplate) => {
      setTemplate(template)
      // If the email is used in a Motion, show the warning modal, otherwise open the edit view.
      if (template && template?.journeys?.length > 0) {
        setIsEditEmailModalOpen(true)
      } else {
        setIsPresentationViewOpen(false)
        setIsEditOpen(true)
      }
    }

    const handlePresentationViewClick = (template: EmailTemplate) => {
      setIsNewTemplate(false)
      setIsPresentationViewOpen(true)
      setTemplate(template)
    }

    const handleConfirmArchiveTemplate = (template: EmailTemplate) => {
      setIsConfirmDeleteModalOpen(true)
      setCurrentlySelectedTemplate(template)
    }

    const handleConfirmEditEmail = () => {
      setIsEditOpen(true)
      setIsPresentationViewOpen(false)
    }

    if (isPresentationViewOpen) {
      return (
        <>
          <EditWarningModal
            isModalOpen={isEditEmailModalOpen}
            actionConfirm={handleConfirmEditEmail}
            setIsModalOpen={setIsEditEmailModalOpen}
            title='Edit Email for All Motions'
            bodyText='Warning: This email is used by multiple Motions. This action will update all Motions that use this email. To only modify this email for this Motion, Save as New Email to create a new copy.'
          />
          <PresentationView
            isInActionFlow={isInActionFlow}
            onEdit={handleEditTemplateClick}
            onClose={() => setIsPresentationViewOpen(false)}
            changeActionConfigPanelFieldValue={changeActionConfigPanelFieldValue}
            closeTemplateListModal={closeTemplateListModal}
          />
        </>
      )
    }

    if (isEditOpen) {
      return (
        <EditTemplate
          onCloseEditView={() => setIsEditOpen(false)}
          changeActionConfigPanelFieldValue={changeActionConfigPanelFieldValue}
          isInActionFlow={isInActionFlow}
          closeTemplateListModal={closeTemplateListModal}
          isNewTemplate={isNewTemplate}
          initializeEdit={initializeEdit}
        />
      )
    }

    return (
      <div className='content'>
        <ArchiveTemplateModal
          isModalOpen={isConfirmDeleteModalOpen}
          setIsModalOpen={setIsConfirmDeleteModalOpen}
          handleArchiveTemplate={() => archiveTemplate(currentlySelectedTemplate!.magnifyTemplateId)}
          isTemplateCurrentlySelected={!!currentlySelectedTemplate}
          setCurrentlySelectedTemplate={setCurrentlySelectedTemplate}
        />
        <EditWarningModal
          isModalOpen={isEditEmailModalOpen}
          actionConfirm={handleConfirmEditEmail}
          setIsModalOpen={setIsEditEmailModalOpen}
          title='Edit Email for All Motions'
          bodyText='Warning: This email is used by multiple Motions. This action will update all Motions that use this email. To only modify this email for this Motion, Save as New Email to create a new copy.'
        />
        <section
          className={`email-template-list-container ${isInActionFlow ? 'modal' : ''}`}
          id='email-template-list'
          data-testid='email-template-list-container'>
          <header>
            <Heading variant='1' level='1'>
              Choose Email
            </Heading>
            {isInActionFlow && closeTemplateListModal && (
              <CloseButton className='close-btn' onClick={() => closeTemplateListModal()}>
                <CloseOutlined />
              </CloseButton>
            )}
          </header>
          <Spin spinning={isTemplateListLoading}>
            <div className='template-card-grid'>
              <div className='template-card unselected new-template' onClick={handleNewTemplateClick}>
                <img src={PlusIcon} alt='+' width={56} height={56} />
                <p>Create New</p>
              </div>
              {templateList.map((template) => (
                <TemplateCard
                  key={`${template.magnifyTemplateId}-${template.magnifyVersion}`}
                  {...template}
                  selected={false}
                  handleEditClick={() => handleEditTemplateClick(template)}
                  handleViewClick={() => handlePresentationViewClick(template)}
                  handleCloneClick={cloneTemplate}
                  handleArchiveClick={() => handleConfirmArchiveTemplate(template)}
                />
              ))}
            </div>
          </Spin>
        </section>
      </div>
    )
  },
)

export default TemplateList
